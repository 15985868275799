import React, {
  CSSProperties,
  MutableRefObject,
  ReactNode,
  RefObject,
} from "react"

import cn from "classnames"

const VideoPlayer = loadable(
  () => import("~/components/VideoPlayer/VideoPlayer")
)
import loadable from "@loadable/component"
import { GatsbyImage } from "gatsby-plugin-image"

import Caption from "./Caption/Caption"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import useVisualViewport from "~/hooks/useVisualViewport"
import { Episode, isEpisode } from "~/models/Show"
import { isPartialVideo, Media } from "~/templates/Cocreation/utils"
import { isIOS } from "~/utils/detector"

import css from "./FullscreenCarouselSlide.module.scss"

interface BaseSlideProps {
  index: number
  posts: Media[] | Episode[]
  refs: MutableRefObject<RefObject<HTMLLIElement>[]>
  ratio: number
  imageArea?: ReactNode
  contentArea?: ReactNode
  awaitData?: boolean
  isFullscreen?: boolean
}

function BaseSlide({
  index,
  posts,
  refs,
  imageArea,
  contentArea,
  isFullscreen,
  ratio,
}: BaseSlideProps) {
  return (
    <li
      className={cn(css.carouselItem, {
        [css.hasOnlyOneItem]: posts.length === 1,
      })}
      ref={refs.current[index]}
    >
      <div
        style={
          {
            "--video-ratio": `${ratio * 100 - 10}vw`,
          } as CSSProperties
        }
        className={cn(css.item, {
          [css.partScreen]: !isFullscreen,
          [css.iosPartScreen]: isIOS(),
        })}
      >
        {imageArea}
        {contentArea}
      </div>
    </li>
  )
}

interface PropTypes {
  item: Media | Episode
  index: number
  posts: Media[] | Episode[]
  refs: MutableRefObject<RefObject<HTMLLIElement>[]>
  isFullscreen?: boolean
  setIsFullscreen?: (fullscreen: boolean) => void
  accentColor?: string
  setIsMuted?: (muted: boolean) => void
  isMuted?: boolean
}

function FullscreenCarouselSlide({
  item,
  index,
  posts,
  refs,
  isFullscreen,
  setIsFullscreen,
  accentColor = "var(--original-color)",
  setIsMuted,
  isMuted,
}: PropTypes) {
  const isCompact = useIsOnCompact()
  const size = useVisualViewport()

  const content = isEpisode(item)
    ? {
        hostedVideoUrl: item.hostedVideoUrl,
        videoPreview: item.videoPoster,
        videoPreviewThumbnail: item.videoPosterThumbnail,
      }
    : item.content

  const getRatio = () => {
    if (isCompact) {
      if (isFullscreen) {
        return (size.viewport.height - 100) / size.viewport.width
      }
      return (size.viewport.height - 260) / size.viewport.width
    }
    if (isFullscreen) {
      return size.viewport.height / size.viewport.width
    }
    return 1
  }

  return (
    <BaseSlide
      ratio={getRatio()}
      {...{ index, posts, isFullscreen, refs }}
      imageArea={
        isPartialVideo(content) ? (
          <div className={cn(css.video, { [css.fullscreen]: isFullscreen })}>
            <VideoPlayer
              pauseOutsideViewport
              poster={content.videoPreview}
              src={content.hostedVideoUrl}
              verticalColor={accentColor}
              format={"other"}
              ratio={getRatio()}
              videoFit="contain"
              modalMode="absolute"
              setIsFullscreen={setIsFullscreen}
              isFullscreenFromParent={isFullscreen}
              setMutedFromParent={setIsMuted}
              muted={isMuted}
            />
          </div>
        ) : (
          <GatsbyImage
            image={content.gatsbyImageData}
            alt={
              !isEpisode(item) && typeof item.caption === "string"
                ? item.caption
                : ""
            }
            className={css.item}
            style={{ ...{ marginBottom: "4px" } }}
          />
        )
      }
      contentArea={!isFullscreen ? <Caption item={item} /> : null}
    />
  )
}

export default FullscreenCarouselSlide
