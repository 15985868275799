import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

import {
  CarouselPartialVideo,
  Image,
  isPartialVideo,
  isVideo,
} from "../../utils"
import playerIcon from "../playerIcon.svg"
import ColorableSVG from "~/components/ColorableSVG/ColorableSVG"
import { Heading } from "~/components/ui"
import useIsOnCompact from "~/hooks/useIsOnCompact"

import css from "./CocreationImageCard.module.scss"
type Props = {
  mediaLength: number
  thumbnail: CarouselPartialVideo | Image | undefined
  caption: string
  allowedIcon?: boolean
  onClick: () => void
}

const CocreationImageCard = (props: Props) => {
  const isCompact = useIsOnCompact()
  const { mediaLength, thumbnail, caption, allowedIcon = true, onClick } = props
  return (
    <div className={css.root} onClick={() => onClick()}>
      {thumbnail &&
        (!isPartialVideo(thumbnail) ? (
          <GatsbyImage
            image={thumbnail.gatsbyImageData}
            alt={caption}
            className={css.image}
          />
        ) : (
          <img
            className={css.image}
            src={thumbnail.videoPreviewThumbnail}
            style={{ verticalAlign: "middle" }}
          />
        ))}
      {allowedIcon ? (
        isVideo(thumbnail) ? (
          <>
            <div className={css.playerBackground} />
            {/* TODO : Change Icon */}
            <ColorableSVG
              href={playerIcon}
              color="white"
              className={css.playerIcon}
            />
          </>
        ) : null
      ) : (
        <Heading
          variant={isCompact ? "large" : "extraLarge"}
          bold
          color={"var(--white-color)"}
          className={css.opaqueFilter}
          noMargin
        >
          {`+ ${mediaLength - 1}`}
        </Heading>
      )}
    </div>
  )
}

export default CocreationImageCard
